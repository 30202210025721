import { Filter } from "@metasearch-io/search-ui";
import { index_map } from "@metasearch-io/shared/constants";
import { IndexCategory, SourceName } from "@metasearch-io/shared/types/open-search";

import { twoWayEnum } from "@/utils";

import { allIndex } from "./components/Filter/SourceFilter/constants";
import { PlanLevel } from "./types/pricing";

export { index_map } from "@metasearch-io/shared/constants";

export const reverse_index_map = twoWayEnum(index_map);

export const indexCategory: Record<IndexCategory, SourceName[]> = {
  News: ["News"],
  Conference: ["Youtube"],
  Research: ["Research"],
  Governance: ["Forum"],
  Dashboard: ["DuneDashboard"],
  Vote: ["SnapShot", "Tally"],
};

export const reversedIndexCategory = Object.fromEntries(
  Object.entries(indexCategory).map(([key, value]) => {
    return [value[0] as SourceName, key as IndexCategory];
  })
) as unknown as Record<SourceName, IndexCategory>;

export const DEFAULT_TWITTER_RANGE_FILTER = [
  // { field: "twitter_tweet_like_count", type: "all", values: [[1, 10]] },
];
export const DEFAULT_DISCORD_RANGE_FILTER = [
  // { field: "discord_message_reaction_count", type: "all", values: [[1, 10]] },
];
export const DEFAULT_DATE_TIME_FILTER: Filter = {
  field: "created_at",
  type: "all",
  values: ["last_90d"],
};

export const DEFAULT_INDEX_FILTER: Filter = {
  field: "index",
  type: "all",
  values: [allIndex],
};
export const allLanguages = ["en", "zh", "ko", "others"];

export const DEFAULT_FILTER = [
  ...DEFAULT_TWITTER_RANGE_FILTER,
  ...DEFAULT_DISCORD_RANGE_FILTER,
  DEFAULT_DATE_TIME_FILTER,
  DEFAULT_INDEX_FILTER,
  {
    field: "language",
    values: [["en"]],
    type: "all",
  },
];
export const EXPIRED_TEMPORARY_PASSWORD_ERROR_MESSAGE = `Temporary password has expired and must be reset by an administrator`;
export const LIGHT_GALLERY_KEY = "B49289AB-6BAF-4F8F-B8C3-5680F359DD86";

export const PREVIEW_HEADER_HEIGHT = 56;
export const HIGHLIGHT_BAR_HEIGHT = 72;

export const listProductFeatureMap = new Map<PlanLevel, string[]>([
  [
    PlanLevel.Free,
    [
      "No of Searches 5 per week",
      "Transcript 5 per week ",
      "Saved Searches Unlimited",
      "Alert 1 alert (daily/weekly)",
      "In-Context TL;DR 5 per week",
      "Follow up Chat 5 per week",
    ],
  ],
  [
    PlanLevel.Pro,
    [
      "No of Searches 50 per week",
      "Transcript 50 per week ",
      "Saved Searches Unlimited",
      "Max 5 alerts",
      "In-Context TL;DR 10 per week",
      "Follow up Chat 10 per week",
    ],
  ],
  // [
  //   PlanLevel.Enterprise,
  //   [
  //     "No of Searches Unlimited",
  //     "Transcript Unlimited",
  //     "Saved Searches Unlimited",
  //     "Alert Unlimited (usage-based)",
  //     "In-Context TL;DR Unlimited",
  //     "Follow up Chat Unlimited",
  //     "API Usage-based ",
  //   ],
  // ],
  [PlanLevel.Business, ["No of Searches Unlimited"]],
]);

export const STRIPE_DISCOUNTS = {
  KAITO_PRO_YEARLY_COUPON: "kaito_pro_yearly_coupon",
  KAITO_BUSINESS_YEARLY_COUPON: "kaito_business_yearly_coupon",
  KAITO_WAITLIST_PRO_DISCOUNT: "waitlist_discount",
  KAITO_WAITLIST_BUSINESS_DISCOUNT: "waitlist_discount_business",
};

// 标志用户自己主动取消，而非程序取消
export const STRIPE_CANCEL_COMMENT = "USER_SELF_CANCEL";

export const SOURCE_BOOST = {
  TIER_ZERO_BOOST_SOURCES: ["Youtube"],
  TIER_ONE_BOOST_SOURCES: ["Podcast", "News"],
  TIER_TWO_BOOST_SOURCES: ["Mirror"],
  TIER_THREE_BOOST_SOURCES: ["Twitter"],
  TIER_FOUR_BOOST_SOURCES: ["Twitter_Space", "Medium"],
};

export const TELEGRAM_URL = "https://t.me/kaitoai2022";

export const assistantId = "asst_VMlNGbFppfcaUbECx4A5iuPn";

export const DEFAULT_ETH_HOT_SEARCH_CARD = [
  {
    episode_id: "fCl_PucMytU",
    episode_title: "You Could've Invented EigenLayer | Kydo 0x - Eigen Labs",
    episode_url: "https://www.youtube.com/watch?v=fCl_PucMytU",
    company_name: "EigenLayer",
    created_at: "2024-02-28T00:00:00.000Z",
    speaker: "Kydo",
    detail: {
      youtube_episode_audio_length: 1137,
      youtube_company_image_url:
        "https://pbs.twimg.com/profile_images/1635879999264940033/_pozth32_400x400.jpg",
      doc_id: "youtube-fCl_PucMytU",
    },
  },
  {
    episode_id: "EYKafKDCSF4",
    episode_title:
      "Chainlink CCIP: Unlocking the Full Potential of Tokenized Assets | Michael Robinson, Shamus Noonan",
    episode_url: "https://www.youtube.com/watch?v=EYKafKDCSF4",
    company_name: "Chainlink",
    speaker: "Seamus Noonan, SPEAKER_00, Michael Robinson",
    created_at: "2024-03-01T00:00:00.000Z",
    detail: {
      youtube_episode_audio_length: 1049,
      youtube_company_image_url:
        "https://kaito-public-assets.s3.us-west-2.amazonaws.com/ticker-icons/LINK/9a75487bc8941f9c2951e1ba0b3d964b",
      doc_id: "youtube-EYKafKDCSF4",
    },
  },
  {
    episode_id: "5_H2WpMaWcE",
    episode_title: "Best of Both Worlds? The Projects Merging Solana and Ethereum",
    episode_url: "https://www.youtube.com/watch?v=5_H2WpMaWcE",
    company_name: "NEON Labs",
    created_at: "2024-03-02T00:00:00.000Z",
    speaker: "Danny Nelson, Neel Somani, Sukanya Parashar, Ajit Tripathi, Keone Hon",
    detail: {
      youtube_episode_audio_length: 1358,
      youtube_company_image_url:
        "https://kaito-public-assets.s3.us-west-2.amazonaws.com/ticker-icons/NEON/d24b61625b460ced095af6c741bae322",
      doc_id: "youtube-5_H2WpMaWcE",
    },
  },
  {
    episode_id: "hHFl02APV0M",
    episode_title:
      "Polkadot: More Than Just a Blockchain? | Bill Laboon - Polkadot/Web3 Foundation",
    episode_url: "https://www.youtube.com/watch?v=hHFl02APV0M",
    company_name: "Polkadot",
    created_at: "2024-03-01T00:00:00.000Z",
    speaker: "Bill Laboon",
    detail: {
      youtube_episode_audio_length: 1216,
      youtube_company_image_url:
        "https://kaito-public-assets.s3.us-west-2.amazonaws.com/ticker-icons/DOT/4e719ad0df2ca46e4d9de6aea5409ddc",
      doc_id: "youtube-hHFl02APV0M",
    },
  },
  {
    episode_id: "m5jJkvCG338",
    episode_title: "Digital Self-Sovereignty in the Age of AI | Illia Polosukhin - NEAR Foundation",
    episode_url: "https://www.youtube.com/watch?v=m5jJkvCG338",
    company_name: "NEAR Foundation",
    created_at: "2024-03-02T00:00:00.000Z",
    speaker: "Illia Polosukhin",
    detail: {
      youtube_episode_audio_length: 936,
      youtube_company_image_url:
        "https://kaito-public-assets.s3.us-west-2.amazonaws.com/ticker-icons/NEAR/5515f26bd8870ae5953ebac9067383ae",
      doc_id: "youtube-m5jJkvCG338",
    },
  },
  {
    episode_id: "LnhEa-lzBEc",
    episode_title:
      "Closing Ceremony: Celebrity Judges, BUIDLathon Winners, Final Thoughts, and More!",
    episode_url: "https://www.youtube.com/watch?v=LnhEa-lzBEc",
    company_name: "__OTHERS__",
    created_at: "2024-03-03T00:00:00.000Z",
    speaker:
      "Ampreet, Sean Bradford, Shaka, SPEAKER_11, SPEAKER_10, Jason Lee, Griff, Casey, SPEAKER_02, SPEAKER_39, SPEAKER_23, SPEAKER_15, SPEAKER_28, Herman, Yongjin Chung, SPEAKER_17, SPEAKER_07, SPEAKER_09, SPEAKER_04, SPEAKER_48, SPEAKER_27, SPEAKER_06, SPEAKER_50, SPEAKER_32, SPEAKER_19, SPEAKER_49, SPEAKER_35, SPEAKER_24, Lawrence, SPEAKER_18, Justin, SPEAKER_13, Jesse, SPEAKER_08, SPEAKER_34, SPEAKER_21, SPEAKER_22, Darren, SPEAKER_03, SPEAKER_01, William, Kelvin, SPEAKER_20, Josh, SPEAKER_41, Kaiser, SPEAKER_45, David, Uday, E. Denver",
    detail: {
      youtube_episode_audio_length: 9554,
      youtube_company_image_url:
        "https://kaito-public-assets.s3.us-west-2.amazonaws.com/youtube-icons/UCgz9NU06t_FkT0Py1Vadczw.jpg",
      doc_id: "youtube-LnhEa-lzBEc",
    },
  },
];

export const DEFAULT_ETHCC_HOT_SEARCH_CARD = [
  {
    episode_id: "7yh34uanfgA",
    episode_title: "EthCC: a keynote with Vitalik Buterin",
    episode_url: "https://www.youtube.com/watch?v=7yh34uanfgA",
    company_name: "Ethereum Foundation",
    created_at: "2024-07-16T00:00:00.000Z",
    speaker: "Vitalik Buterin",
    detail: {
      youtube_episode_audio_length: 2541,
      youtube_company_image_url:
        "https://kaito-public-assets.s3.us-west-2.amazonaws.com/ticker-icons/ETH/31fdc17042186e3c6d95269281e06a94",
      doc_id: "youtube-7yh34uanfgA",
    },
  },
  {
    episode_id: "AZipt43zctg",
    episode_title: "EthCC: Mitigating MEV with FHE   Blind Arbitrage on Ethereum",
    episode_url: "https://www.youtube.com/watch?v=AZipt43zctg",
    company_name: "Flashbots",
    created_at: "2024-07-16T00:00:00.000Z",
    speaker: "Jonathan Passerat-Palmbach",
    detail: {
      youtube_episode_audio_length: 1211,
      youtube_company_image_url:
        "https://kaito-public-assets.s3.us-west-2.amazonaws.com/ticker-icons/FLASHBOTS/FLASHBOTS.ico",
      doc_id: "youtube-AZipt43zctg",
    },
  },
  {
    episode_id: "0ioyK9u4Ams",
    episode_title: "EthCC: Don't let your friends do MEV",
    episode_url: "https://www.youtube.com/watch?v=0ioyK9u4Ams",
    company_name: "Go Ethereum",
    created_at: "2024-07-18T00:00:00.000Z",
    speaker: "Marius van der Wijden",
    detail: {
      youtube_episode_audio_length: 1341,
      youtube_company_image_url:
        "https://pbs.twimg.com/profile_images/1605238709451898881/Kl-bliNn_400x400.jpg",
      doc_id: "youtube-0ioyK9u4Ams",
    },
  },
  {
    episode_id: "-Yn2SYPnVp4",
    episode_title: "EthCC: How to price your Preconfirmation",
    episode_url: "https://www.youtube.com/watch?v=-Yn2SYPnVp4",
    company_name: "Primev",
    created_at: "2024-07-16T00:00:00.000Z",
    speaker: "Murat Akdeniz",
    detail: {
      youtube_episode_audio_length: 2073,
      youtube_company_image_url:
        "https://kaito-public-assets.s3.us-west-2.amazonaws.com/ticker-icons/PRIMEV/PRIMEV.jpg",
      doc_id: "youtube--Yn2SYPnVp4",
    },
  },
  {
    episode_id: "8RrycgqUQAY",
    episode_title:
      "EthCC: Building True Unstoppability: Protocol Hosted Decentralised Protocol Development",
    episode_url: "https://www.youtube.com/watch?v=8RrycgqUQAY",
    company_name: "Web3 Foundation",
    created_at: "2024-07-16T00:00:00.000Z",
    speaker: "Gavin Wood",
    detail: {
      youtube_episode_audio_length: 1301,
      youtube_company_image_url:
        "https://pbs.twimg.com/profile_images/1162751266432442369/cjjjE5un_400x400.png",
      doc_id: "youtube-8RrycgqUQAY",
    },
  },
];

export const DEFAULT_SKELETON = 3;

export const DEFAULT_ETH_SEARCH_LABEL = [
  "Any new projects under development?",
  "What is restaking?",
  "What are the use cases of AI in web3?",
  "What is tokenized assest?",
  "How is metaverse going?",
  "How does zero-knowledge proof work?",
];

export const DEFAULT_ETHCC_SEARCH_LABEL = [
  "What is intents?",
  "What is FHE and how could it be applied in Web3?",
  "What are the key principles that Web3 protocols can adopt to ensure they remain relevant and adaptable in a fast-changing technological environment?",
  "How is Ethereum tackling protocol simplification and technical debt reduction?",
];

export const single_article_assistant = "asst_4ugOGjLAtyyJQSlnN61L8HRt";

export const BASIC_EVENT_LOG_API_AUTH = {
  username: "kaito",
  password: "123!@#qweQWE",
};

import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import { ClearIcon } from "@/components/Icon";
import { VerifiedIcon } from "@/components/Icon";
import { KAvatar } from "@/components/KAvatar";

export type valueType = {
  value: string;
  username: string;
  name: string;
  verified: boolean;
  avatarUrl: string;
  replaceAvatar?: boolean;
};

type removeSelectedItemType = (v: valueType) => void;

const SelectedItemRender = ({
  value,
  removeSelectedItem,
  isActivated,
}: {
  value: valueType;
  removeSelectedItem: removeSelectedItemType;
  isActivated: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpoen] = useState(false);

  useEffect(() => {
    const hasToolTip = nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth;
    const handler = () => {
      if (hasToolTip) {
        if (isOpen) {
          setIsOpoen(false);
        } else {
          setIsOpoen(true);
        }
      }
    };
    const curr = containerRef?.current;
    curr?.addEventListener("mouseenter", handler);
    curr?.addEventListener("mouseleave", handler);
    return () => {
      curr.removeEventListener("mouseenter", handler);
      curr.removeEventListener("mouseleave", handler);
    };
  }, [isOpen]);

  const avatarDom =
    value.avatarUrl === "Search" || !value.avatarUrl ? null : (
      <KAvatar
        src={value.avatarUrl}
        priorityParams={{ source: "Twitter", id: value.value }}
        skip={!value.replaceAvatar || value.avatarUrl === "Search"}
      ></KAvatar>
    );

  const elementToShow = (
    <Flex gap="4px">
      {avatarDom}
      <Flex flexDirection="column">
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
          color="#FFFFFF"
          wordBreak="break-all"
        >
          {value.verified && <VerifiedIcon ml="4px" fontSize="12px" />}@{value.username}
        </Text>
        {value.avatarUrl != "Search" && (
          <Text
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
            wordBreak="break-all"
            color="whiteAlpha.200"
          >
            {value.name}
          </Text>
        )}
      </Flex>
    </Flex>
  );

  return (
    <Tooltip placement="top" label={elementToShow} isOpen={isOpen} p="8px" minW="356px">
      <Flex
        p="2px 4px"
        alignItems="center"
        gap="2px"
        bg={isActivated ? "#474f5c" : "rgba(255, 255, 255, 0.10)"}
        borderRadius="4px"
        maxW="100%"
        ref={containerRef}
      >
        {avatarDom}
        <Text
          fontSize="14px"
          lineHeight="20px"
          fontWeight="500"
          color="#ffffff"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace={"nowrap"}
          ref={nameRef}
        >
          @{value.username}
        </Text>
        <ClearIcon
          onClick={(e: any) => {
            e.stopPropagation();
            removeSelectedItem(value);
          }}
          width="12px"
          height="12px"
          flex="none"
          ml="4px"
          cursor="pointer"
          color="whiteAlpha.200"
        />
      </Flex>
    </Tooltip>
  );
};

export default SelectedItemRender;

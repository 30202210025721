export const ENV = {
  DEPLOYMENT_PLATFORM: process.env.DEPLOYMENT_PLATFORM,
  EDGE_LAMBDA_USER_ACCESS_KEY_ID: process.env.EDGE_LAMBDA_USER_ACCESS_KEY_ID,
  EDGE_LAMBDA_USER_SECRET_ACCESS_KEY: process.env.EDGE_LAMBDA_USER_SECRET_ACCESS_KEY,
  stage: process.env.NEXT_PUBLIC_STAGE,
  localhostPort: process.env.NODE_ENV === "development" ? process.env.LOCALHOST_PORT || 3000 : 8080,
  cognitoClientId: process.env.COGNITO_CLIENT_ID,
  cognitoUserPoolId: process.env.COGNITO_USER_POOL_ID,
  homepageUrl: process.env.NEXT_PUBLIC_HOMEPAGE_URL,
  ALERT_API_URL: process.env.ALERT_API_ENDPOINT,
  PORTAL_API_URL: process.env.PORTAL_API_ENDPOINT,
  APPCONFIG_APPLICATION_NAME: process.env.APPCONFIG_APPLICATION_NAME ?? "MetaSearchApplication",
  APPCONFIG_ENVIRONMENT_NAME: process.env.APPCONFIG_ENVIRONMENT_NAME ?? "beta-uswest2-Environment",
  APPCONFIG_CONFIG_PROFILE_NAME:
    process.env.APPCONFIG_CONFIG_PROFILE_NAME ??
    "beta-uswest2-RisingwaveConfig-HostedConfigurationProfile",
  AMPLIFY_APP_BACKEND_ROLE_ARN: process.env.AMPLIFY_APP_BACKEND_ROLE_ARN,
  // enable api encryption or not
  ENCRYPTION_ENABLED: process.env.NEXT_PUBLIC_ENCRYPTION_ENABLED === "false" ? "false" : "true",
};

import { KImage } from "@metasearch-io/shared/components";
import loadingGif from "public/loading.gif";
import { useCallback, useEffect, useMemo, useState } from "react";

import { BaseField } from "./types";

type ObjectWithValue = {
  value: string;
  [k: string]: any;
};

type ProxyFetcher = (v: ObjectWithValue[]) => Promise<ObjectWithValue[]>;
type ProxyFomatter = (v: any[]) => ObjectWithValue[];

const ProxyComp = ({
  onChange,
  value: shortValue,
  filters,
  fetcher,
  formatter,
  Comp,
  cacheValue,
}: {
  onChange: (value: ObjectWithValue[]) => void;
  /** value or something else, used to fetch */
  value: ObjectWithValue[];
  filters?: any;
  Comp?: BaseField["renderFilterField"];
  fetcher: ProxyFetcher;
  formatter: ProxyFomatter;
  cacheValue: { current: Record<string, ObjectWithValue> };
}) => {
  const [loading, setLoading] = useState(false);
  /** force update when change cache value */
  const [count, setCount] = useState(0);

  const setCacheValue = useCallback(
    (k, v) => {
      cacheValue.current[k] = v;
      setCount(count + 1);
    },
    [cacheValue, count]
  );

  useEffect(() => {
    const fetchItems = shortValue.filter(({ value }) => !cacheValue.current[value]);
    if (fetchItems.length > 0) {
      setLoading(true);
      fetcher(fetchItems)
        .then((res) => {
          res.forEach((item) => {
            setCacheValue(item.value, item);
          });
        })
        .catch()
        .finally(() => setLoading(false));
    }
  }, [shortValue]);
  const proxyOnChange = useCallback(
    (v) => {
      v.forEach((item) => {
        cacheValue.current[item.value] = item;
        setCacheValue(item.value, item);
      });

      return onChange(formatter(v));
    },
    [cacheValue, formatter, onChange, setCacheValue]
  );
  const proxyValue = useMemo(() => {
    const res = [];
    shortValue.forEach(({ value }) => {
      const target = cacheValue.current[value];
      if (target) {
        res.push(target);
      }
    });
    return res;
  }, [cacheValue, shortValue, count]);
  if (loading)
    return (
      <div className="flex justify-center w-full">
        <KImage src={loadingGif.src} width={20} height={20} alt="loading"></KImage>
      </div>
    );
  return <>{Comp(proxyOnChange, proxyValue, filters)}</>;
};

/**
 * use case
 * filterFieldHOC(
 *   () => Promise.resolve([{ value: 1 }]),
 *   (v) => v.map((item) => ({ value: item.id }))
 * );
 * @param Comp renderFilterField in SourceFilter/constants.tsx
 * @param fetcher init data from origin
 * @param formatter delete unnecessary properties
 * @returns
 */
export const filterFieldHOC = (
  Comp: BaseField["renderFilterField"],
  fetcher: ProxyFetcher,
  formatter: ProxyFomatter
) => {
  const cacheValue = { current: {} };
  return function ProxyCompWrapperFun(onChange: (value: any) => void, value: any, filters?) {
    return (
      <ProxyComp
        onChange={onChange}
        value={value}
        filters={filters}
        Comp={Comp}
        fetcher={fetcher}
        formatter={formatter}
        cacheValue={cacheValue}
      ></ProxyComp>
    );
  };
};

import qs from "qs";

import { isObject } from "./isObject";
import preserveTypesEncoder from "./preserveTypesEncoder";

// Helper function to flatten nested objects
function flattenObject(obj, prefix = "") {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : "";
    if (typeof obj[k] === "object" && obj[k] !== null && !Array.isArray(obj[k])) {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
}

// Helper function to unflatten objects
function unflattenObject(obj) {
  const result = {};
  for (const key in obj) {
    const keys = key.split(".");
    keys.reduce((acc, k, i) => {
      if (i === keys.length - 1) {
        acc[k] = obj[key];
      } else {
        acc[k] = acc[k] || {};
      }
      return acc[k];
    }, result);
  }
  return result;
}

/**
 * new search-ui query stringify and parser
 *
 * Solution:
 *  stringify flatten nested objects and encode values
 */
export const newSearchQueryString = {
  parse: (str: string) => {
    const parsed = qs.parse(str, {
      ignoreQueryPrefix: true,
      decoder: preserveTypesEncoder.decode,
      arrayLimit: 1000,
      // allowSparse: true,
      // allowDots: true,
      // decodeDotInKeys: true,
    });
    // Convert JSON strings back to objects
    Object.keys(parsed).forEach((key) => {
      try {
        parsed[key] = JSON.parse(parsed[key] as string);
      } catch (e) {
        // If it's not a valid JSON string, keep the original value
      }
    });

    // Unflatten the object
    return unflattenObject(parsed);
  },
  stringify(object: Record<string, unknown>): string {
    // Flatten nested objects
    const flatData = flattenObject(object);

    // Convert any remaining complex values to JSON strings
    Object.keys(flatData).forEach((key) => {
      if (typeof flatData[key] === "object" && flatData[key] !== null) {
        flatData[key] = JSON.stringify(flatData[key], (k, v) => {
          // compatible with old stringify rule
          // but JSON.stringify cannot change boolean result
          if (Array.isArray(v)) {
            if (!v.length) return undefined;
            // filter undefined & [] & {}
            const filterArr = v.filter((item) => {
              if (Array.isArray(item)) return item.length > 0;
              if (isObject(item)) return Object.keys(item).length > 0;
              return item !== undefined;
            });
            if (!filterArr.length) return undefined;
            return filterArr;
          }
          if (v === null) return "";
          if (v === false) return "false";
          return v;
        });
      }
    });

    return qs.stringify(flatData, {
      encoder: preserveTypesEncoder.encode,

      arrayFormat: "repeat",
      encode: true,
      // allowDots: true,
      // encodeDotInKeys: true,
      // skipNulls: true,
    });
  },
};

export { replaceFilterFieldValue } from "./replaceFilterFieldValue";

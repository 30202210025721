import { KImage } from "@metasearch-io/shared/components";
import { useLayoutEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import defaultAvatar from "@/public/default_twitter_avatar.png";

export const KAvatar = ({
  className,
  priorityParams,
  src: srcProp,
  skip,
}: {
  className?: string;
  priorityParams?: {
    source: "Twitter";
    [key: string]: string;
  };
  src?: string;
  skip?: boolean;
}) => {
  const [src, setSrc] = useState("");
  const priorityUrl = useMemo(() => {
    if (priorityParams.source === "Twitter" && priorityParams.id) {
      return `//asset.cdn.kaito.ai/twitter-user-profile-img/${priorityParams.id}.jpg`;
    }
  }, [priorityParams.id, priorityParams.source]);

  useLayoutEffect(() => {
    if (skip) {
      setSrc(srcProp);
      return;
    }
    if (srcProp && priorityUrl) {
      const img = new Image();
      img.src = priorityUrl;
      img.onload = () => {
        setSrc(priorityUrl);
      };
      img.onerror = () => {
        setSrc(srcProp);
      };
    } else if (srcProp) {
      setSrc(srcProp);
    } else if (priorityUrl) {
      setSrc(priorityUrl);
    }
  }, [priorityUrl, skip, srcProp]);

  return (
    <Avatar className={twMerge("w-5 h-5", className)}>
      <AvatarImage src={src} />
      <AvatarFallback>
        <KImage
          unoptimized
          width={100}
          height={100}
          className="h-full w-full"
          src={defaultAvatar.src}
          alt="avatar"
        ></KImage>
      </AvatarFallback>
    </Avatar>
  );
};
